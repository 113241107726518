.text-item {
  overflow: hidden;

  &__title {
    @include font(18, 21.6px, 400);
    color: $neutral-100;
    text-transform: uppercase;
    margin: 16px 0;

    @include md() {
      @include font(16, 24px);
    }

    &:hover {
      text-decoration: underline;
    }

    &_bold {
      font-weight: 700;
      text-transform: none;
      transition: color 0.2s ease-out;

      @include md() {
        @include font(16, 22.4px);
      }
    }
  }

  &:hover {
    img {
      mix-blend-mode: normal;
    }

    .text-item__title_bold {
      text-decoration: none;
      color: #F26106;
    }
  }

  &__description {
    color: $neutral-90;
    @include font(16, 24px, 400);
    @include line-clamp(3);
    font-family: 'Montserrat';

    &_size-1 {
      -webkit-line-clamp: 1;
      line-clamp: 1;

      @include sm() {
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }

      @include xs() {
        -webkit-line-clamp: 1;
        line-clamp: 1;
      }
    }

    &_size-2 {
      -webkit-line-clamp: 2;
      line-clamp: 2;

      @include xs() {
        -webkit-line-clamp: 1;
        line-clamp: 1;
      }
    }

    &_size-3 {
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
  }

  &__author {
    @include font(14, 24px, 400);
    color: $neutral-100;

    a {
      color: $neutral-100;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // mix-blend-mode: luminosity;
    border: solid 2px $neutral-100;
  }

  &__top-bar {
    display: flex;
    align-items: center;
  }

  &__other-categories {
    .text-item__title {
      margin-top: 0;
      @include line-clamp(3);

      @include sm() {
        @include line-clamp(5);
      }
    }
  }

  &_grid-type {
    height: 192px;
    border-bottom: solid 1px $neutral-100;
    margin-top: 32px;
    padding-bottom: 32px;

    @include sm() {
      height: auto;
      padding-bottom: 32px;
    }

    @include xs() {
      height: 172px;
    }


    &:first-child {
      margin-top: 0;
    }
  }

  &__data-wrapper {
    display: flex;
    justify-content: space-between;

    img {
      width: 108px;
      height: 108px;
      margin-left: 24px;
      margin-bottom: 8px;
    }

    .text-item__title {
      -webkit-line-clamp: 5;
      line-clamp: 5;
      max-width: 280px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      margin-bottom: 32px;
      margin-top: 0;

      @include sm() {
        margin-bottom: 16px;
      }
    }
  }
}