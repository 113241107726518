.other-categories {
  padding-bottom: 82px;
  position: relative;
  overflow: hidden;

  @include md() {
    padding-top: 8px;
    padding-bottom: 24px;
  }

  &__decoration-1 {
    position: absolute;
    right: 0;
    top: 242px;
    z-index: -1;

    @include md() {
      top: 314px;
      right: -80px;
    }
  }

  &__decoration-2 {
    position: absolute;
    display: none;
    z-index: -1;

    @include md() {
      display: block;
      left: 0;
      bottom: 592px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @include md() {
      grid-template-columns: 1fr;
    }

    >div {
      padding-right: 16px;
      padding-left: 16px;
      border-right: solid 2px $neutral-100;

      @include md() {
        padding: 0;
        border-right: 0;
        margin-top: 48px;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &__title-wrapper {
    padding-bottom: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px $neutral-100;

    >svg {
      position: absolute;

      &.first-svg {
        left: 10px;
        top: -20px;

        @include lg() {
          @include size(131px, 71px)
        }
      }

      &.second-svg {
        left: -12px;
        top: 8px;

        @include lg() {
          @include size(182px, 18px)
        }
      }

      &.third-svg {
        left: -22px;
        top: -18px;

        @include lg() {
          @include size(151px, 61px)
        }
      }
    }

  }

  &__title {
    @include font(32, 38.4px, 900);
    margin-right: 12px;

    @include lg() {
      @include font(24, 28.8px, 900);
    }
  }
}