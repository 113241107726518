.badge {
  text-transform: uppercase;
  padding: 4.5px 12px 1.5px 12px;
  border-radius: 34px;
  border: solid 1.5px $neutral-100;
  display: inline-block;
  @include font(14, 16.75px, 400);
  color: $neutral-100;

  &_yellow {
    background-color: $primary;
    border-color: $primary;
  }
  &_green {
    background-color: $primary-green;
  }
  &_orange {
    background-color: #F26106;
  }
  &_platinum {
    background-color: $primary-platinum;
  }
  &_purple {
    background-color: $primary-purple;
    border-color: $primary-purple;
  }
  &_white {
    border-color: $white;
    color: $white;
  }
}