.video-item {
  @include size(528px, 528px);
  border: solid 2px $neutral-100;
  padding: 25px 24px 32px calc(32px + 9px);
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  overflow: hidden;

  @include sm() {
    @include size(356px, 356px);
    padding: 16px 16px 13px 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include sm() {

      img,
      svg {
        &:first-child {
          @include size(17.25px, 12px);
        }

        &:last-child {
          @include size(52.5px, 12.5px);
        }
      }
    }
  }

  &__content {
    margin-top: auto;
  }

  &_type-1 {
    .video-item__title {
      @include font(20, 24px, 400);
      color: $neutral-100;
      max-width: 290px;
      text-transform: uppercase;
      position: absolute;
      bottom: -200px;
      transition: bottom 0.2s ease-in-out;

      @include font(18, 25.2px);

      @include sm() {
        max-width: 265px;
      }

      span {
        box-shadow: 8px 0 0 $primary, -8px 0 0 $primary;
        padding: 8px 0;
        background-color: $primary;
        line-height: 2.2;

        @include sm() {
          box-shadow: 0 0 0 11px $primary;
          line-height: 2.35;
        }
      }
    }

    &:hover {
      .video-item__title {
        bottom: 32px;

        @include sm() {
          bottom: 13px;
        }
      }

      .video-item__decoration-circle {
        right: 105px;

        @include sm() {
          right: 118px;
        }
      }

      .video-item__bg-image {
        background-color: rgb(203, 186, 237, 0.95);
      }
    }
  }

  &_type-2 {
    background-color: $primary;
    position: relative;

    &_collage {
      position: absolute;
      left: 0;
      top: 0;
    }

    .video-item__title {
      @include font(32, 38.4px, 900);
      max-width: 450px;
      color: $neutral-100;

      @include sm() {
        @include font(24, 28.8px);
      }
    }
  }

  &__description {
    color: $neutral-100;
    @include font(16, 19.2px, 400);
    max-width: 280px;
    margin-top: 16px;
    margin-bottom: 24px;

    @include sm() {
      margin: 16px 0;
    }
  }

  &__subscribe-btn {
    @include font(16, 24px, 700);
    color: $neutral-100;
    display: flex;

    img,
    svg {
      margin-left: 7px;
      position: relative;
      top: -3px;
    }
  }

  &__bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    @include size(100%, 100%);
    mix-blend-mode: luminosity;
    background-color: #000;
    transition: background-color 0.2s ease-in-out;

    img {
      mix-blend-mode: luminosity;
      @include size(100%, 100%);
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.65%, rgba(0, 0, 0, 0.4) 85.42%);
    }
  }

  &__decoration-circle {
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    @include center-flex();
    @include size(111px, 111px);
    background-color: $primary;
    right: -150px;
    top: 193px;
    transform: rotate(-30deg);
    transition: right 0.2s ease-in-out;

    @include sm() {
      top: 68px;
    }

    span {
      color: $neutral-100;
      text-transform: uppercase;
      @include font(14, 17px, 400);

      @include sm() {
        @include font(12, 14.5px);
      }
    }
  }
}