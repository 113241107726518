.videos {
  padding: 80px 0;
  background-color: $primary-purple;
  overflow: hidden;

  @include sm() {
    padding: 35px 0;
  }

  .arrow-navigation {
    top: 15px;

    @include sm() {
      top: auto;
      bottom: 7px;
      left: 0;
      width: 100%;

      svg {
        min-width: 179px;
      }
    }

    .long-arrow {
      display: none;

      @include sm() {
        display: block;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      margin-left: 0;
      width: auto;
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 32px;
    position: relative;

    @include md() {
      grid-template-columns: 1fr;
    }
  }

  &__decoration_1 {
    position: absolute;
    top: -50px;
    left: 240px;
    @include md() {
      left: 60px;
      top: -28px;
      transform: rotate(-4deg);
    }
  }

  &__decoration_2 {
    position: absolute;
    top: 216px;
    left: -52px;
    z-index: 0;
    @include md() {
      z-index: 2;
      top: 0;
    }
  }

  &__left-side {
    display: flex;
    flex-direction: column;
  }

  &__socials {
    display: flex;
    margin-top: auto;

    &-item {
      @include font(24, 24px, 700);
      color: $neutral-100;
      display: flex;
      border-radius: 128px;
      padding: 10.5px 14px 7px 14px;
      max-height: 44px;
      border: solid 2px $neutral-100;

      @include xs() {
        width: 100%;
        justify-content: center
      }

      svg,
      img {
        @include size(9.5px, 9.5px);
        margin-left: 8px;
      }
    }

    @include md() {
      margin-top: 32px;
    }
  }

  &__title {
    @include font(48, 57.6px, 800);
    overflow-wrap: break-word;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
    max-width: 416px;
    min-width: 416px;

    @include sm() {
      max-width: auto;
      min-width: auto;
    }

    @include md() {
      @include font(24, 28.8px);
      text-transform: none;
    }

    span {
      &:first-child {
        font-weight: 700;
      }

      &:nth-child(2) {
        font-weight: 500;
      }

      &:last-child {
        font-weight: 300;
      }
    }
  }

  .swiper {
    padding-top: 60px;
    position: inherit;
    left: 0;
    width: 70vw;
    padding-right: 10vw;
    z-index: 100;

    @include md() {
      padding-right: 7vw;
      width: 100vw;
      padding-top: 0;
    }

    @include sm() {
      padding-bottom: 48px;
    }
  }

  .swiper-slide {
    width: fit-content;
  }
}