@import "./load-more.scss";

.grid-item {
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: solid 2px $neutral-100;
  overflow: hidden;
  min-height: 416px;

  @include sm {
    min-height: 382px;
  }

  a {
    display: block;
  }

  &__header {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
  }

  &__description {
    @include font(16, 19.2px, 400);
    margin-top: -8px;
    max-width: 280px;
  }

  &__author {
    @include font(14, 22px, 400);
  }

  &_type-1 {
    padding: 24px;

    .grid-item__title {
      a {
        color: $neutral-100;
      }
    }

    .grid-item__author {
      a {
        color: $neutral-100;
      }
    }
  }

  &_image {
    padding: 24px;
    padding-bottom: 40px;
    border: none;

    &::before {
      content: '';
      @include size(100%, 100%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.296875) 68.75%, rgba(0, 0, 0, 0.6) 100%);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .grid-item__title {
      @include font(18, 21.6px, 900);
      color: $primary;
      margin-bottom: 0;
    }

    &_purple {
      .grid-item__title {
        color: $primary-purple;
      }
    }
  }

  &_image-color {
    &:hover {
      .grid-item__bg-image {
        filter: grayscale(0);
        transform: scale(1.05);
      }
    }

    svg {
      path {
        fill: #fff;
      }
    }

    .grid-item__title {

      a,
      span {
        color: $neutral-100;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .grid-item__content {
      margin-left: 9px;
    }

    .grid-item__author,
    .grid-item__description {
      margin-left: -9px;
    }

    .grid-item__author {
      margin-bottom: 16px;
    }

    .grid-item__title {
      a {
        color: $neutral-100;
      }
    }

    .grid-item__author,
    .grid-item__description {
      color: $white;

      a {
        color: $white;
      }
    }

    &::before {
      content: '';
      @include size(100%, 100%);
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.296875) 68.75%, rgba(0, 0, 0, 0.6) 100%);
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  &_type-big {
    position: relative;

    @include sm {
      min-height: 477px !important;
    }

    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.288) 66.67%, rgba(0, 0, 0, 0.6) 100%);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      @include size(100%, 100%);
    }

    .badge {
      margin: 0 auto;
    }

    .grid-item__title,
    .grid-item__author,
    .grid-item__description {
      color: $primary;
      text-transform: none;

      a {
        color: $primary;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .grid-item__author {
      display: block;
      text-align: center;
      margin-top: 32px;
    }

    .grid-item__title {
      @include font(32, 38.4px, 900);
      text-align: center;
      margin-bottom: 0;

      @include sm() {
        @include font(20, 24px);
        text-transform: none;
        max-width: 100%;
      }
    }

    .grid-item__content {
      padding-bottom: 24px 0;

      @include sm() {
        padding: 16px 0;
      }
    }

    &_img {
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      @include size(100%, 100%);
      z-index: 0;
      padding: 0;
    }
  }

  &__title {
    text-transform: uppercase;
    @include font(20, 24px, 400);
    max-width: 100%;
    margin-bottom: 24px;

    a {
      color: $neutral-100;
    }

    &:hover {
      text-decoration: underline;
    }

    @include sm() {
      @include font(18, 21.6px);
    }

    span {
      line-height: 2.25;
      box-decoration-break: clone;

      @include xs() {
        line-height: 2.25;
      }
    }
  }

  &_purple-color {
    background-color: $primary-purple;
  }

  &_green-color {
    background-color: $primary-green;
  }

  &_orange-color {
    background-color: $primary-orange;

    .grid-item__title,
    .grid-item__author {
      a {
        color: $white;
      }
    }

    .grid-item__description {
      color: $white;
    }
  }

  &_image-primary-color {
    .grid-item__title {
      span {
        box-shadow: 8px 0 0 $primary, -8px 0 0 $primary;
        padding: 8px 0;
        background-color: $primary;
      }
    }

    .badge {
      background-color: $primary;
    }
  }

  &_image-purple-color {
    .grid-item__title {
      span {
        box-shadow: 8px 0 0 $primary-purple, -8px 0 0 $primary-purple;
        padding: 8px 0;
        background-color: $primary-purple;
      }
    }

    .badge {
      background-color: $primary-purple;
    }
  }

  &_image-green-color {
    .grid-item__title {
      span {
        box-shadow: 8px 0 0 $primary-green, -8px 0 0 $primary-green;
        padding: 8px 0;
        background-color: $primary-green;
      }
    }

    .badge {
      background-color: $primary-green;
    }
  }

  &_image-orange-color {
    .grid-item__title {
      span {
        box-shadow: 8px 0 0 $primary-orange, -8px 0 0 $primary-orange;
        padding: 8px 0;
        background-color: $primary-orange;
      }
    }

    .badge {
      background-color: $primary-orange;
    }
  }

  &__bg-image {
    position: absolute;
    transition: transform 0.2s ease-in-out;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    top: 0;
    z-index: 0;
    // filter: grayscale(1);
  }

  &__star-img {
    position: absolute;
    right: 0;
    bottom: -4px;
    // filter: grayscale(1);
    z-index: 10;

    @include lg-1400() {
      right: -20px;
    }

    @include md() {
      right: -40px;
    }

    @include sm() {
      right: 0;
    }

    @include sm() {
      right: -20px;
    }
  }

  &__bottom-logo {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }

  &__polling {
    .grid-item__title {
      margin-bottom: 40px;

      &:hover {
        text-decoration: none;
      }

      @include sm() {
        padding-top: 54px;
      }
    }

    .grid-item__star-img {
      bottom: -4px;
      right: 0;
      top: auto;
    }

    &-article {
      height: 519px;

      a {
        text-decoration: none !important;
      }

      .grid-item__title {
        max-width: 100%;

        @include xs() {
          max-width: 250px;
        }
      }

      .polling__item {
        width: fit-content;
      }

      .polling__votes {
        margin-top: 40px;
      }

      .grid-item__star-img {
        right: -10px;

        @include sm() {
          svg {
            @include size(130px, 130px);
            right: 0;
          }
        }
      }

      .polling__item-checkbox {
        border-color: #E0D1FF;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;

    &-top {
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      height: 100%;
    }
  }

  &_primary-color {
    background-color: $primary;
  }

  &_purple-color {
    background-color: $primary-purple;
  }

  &_dark-color {
    background-color: $neutral-100;

    svg {
      path {
        fill: $white;
      }
    }

    .grid-item__title,
    .grid-item__author,
    .grid-item__description {
      color: $white;

      a {
        color: $white;
      }
    }

  }

  &_centered-item {
    margin: 0 auto;
    padding: 24px;

    .grid-item__title {
      padding: 8px 16px 3px 16px;
      border-radius: 144px;

      span {
        line-height: 2.2;
        box-shadow: 8px 0 0 $primary-orange, -8px 0 0 $primary-orange;
        padding: 8px 0;
        background-color: $primary-orange;

        @include xs() {
          line-height: 2.25;
        }

        text-align: center;
      }
    }

    .grid-item__content {
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
  }


  &__decorative-social {
    display: inline-block;
    margin: 0 auto;
  }

  &_social-promo {
    height: 100%;
    padding: 24px 32px 32px 32px;
    border: solid 2px $neutral-100;

    &-fb {
      background-color: $primary;

      .grid-item__decorative-social {
        position: relative;
        left: -10px;
      }
    }

    &-tg {
      background-color: $primary-green;

      .grid-item__decorative-social {
        position: relative;
        @include size(241px, 268px);
        left: -12px;
      }
    }

    &-inst {
      background-color: $primary-purple;

      .grid-item__decorative-social {
        position: relative;
        left: -10px;
      }
    }

    image {
      // filter: grayscale(1);
    }

    .grid-item__title {
      @include font(24, 28.8px, 900);
      text-transform: none;
      text-align: center;
      max-width: 100%;
      padding-top: 12px;
    }

    .grid-item__description {
      display: block;
      text-align: center;
      margin: 0 auto;
      max-width: 350px;
      margin-bottom: 24px;
    }

    .btn {
      @include font(16, 24px, 700);
      color: $neutral-100;
      text-align: center;

      svg {
        position: relative;
        transition: all 0.2s ease-in-out;
        top: 0;
        left: 0;
      }

      &:hover {
        svg {
          top: -3px;
          left: 3px;
        }
      }
    }
  }
}