.search {
  min-width: 100%;
  position: relative;
  height: fit-content;

  &__input {
    height: 38px;
    -webkit-appearance: none;
    border-radius: 0;
    appearance: none;
    outline: none;
    border: none;
    border-bottom: solid 1px #000;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    @include font(24, 30px, 700);
    color: $neutral-100;
    padding-right: 20px;
    padding-bottom: 8px;

    &::placeholder {
      color: $neutral-60;
    }
  }
}