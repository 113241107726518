.sidebar-bg-image-item {
  position: relative;
  @include size(100%, 250px);
  min-height: 250px;
  padding: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%, 100%);
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.288) 65.62%, rgba(0, 0, 0, 0.6) 100%);
  }

  img {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    @include size(100%, 100%);
    object-fit: cover;
  }

  >a {
    display: flex;
    flex-direction: column;
    @include size(100%, 100%);
    justify-content: space-between;
  }

  .badge {
    position: relative;
    width: fit-content;
    z-index: 2;
  }

  &__title {
    margin-top: auto;
    position: relative;
    z-index: 2;
    @include font(16, 19.2px, 900);
    color: #D8FA6F;
  }
}

.sidebar-image-item {
  >a {
    display: block;
  }

  img {
    height: auto;
    object-fit: cover;
    width: 100%;
    max-height: 170px
  }

  &__title {
    @include font(18, 21.6px, 700);
    color: $neutral-100;
    margin-top: 16px;
  }
}