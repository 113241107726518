.load-more {
  display: inline-block;
  text-align: center;
  @include font(16, 24px, 700);
  color: $text-color;
  cursor: pointer;
  svg {
    position: relative;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    svg {
      transition: all 0.2s ease-in-out;
      top: 4px;
      left: 4px;
    }
  }
}