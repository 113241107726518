.footer {

  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a {
    color: $neutral-100;
    display: block;
  }

  &__logo {
    display: block;
    margin-left: -36px;
    position: relative;
    margin-top: -20px;
    z-index: -1;

    @include sm() {
      top: 0;
      margin-left: -26px;
      margin-top: 0;
    }

    &_mob {
      display: none;

      @include sm() {
        display: block;
        padding-top: 17px;
        padding-bottom: 21px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    padding: 16px 0;
    margin-bottom: 64px;

    @include sm() {
      flex-direction: column;
      padding-top: 32px;
      padding-bottom: 24px;
      margin-bottom: 0;
    }

    a {
      @include font(14, 24px, 700);
      text-transform: uppercase;
      padding-right: 24px;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }

      &:last-child {
        padding-right: 0;
      }

      @include sm() {
        padding-right: 0;
        padding-top: 24px;

        &:first-child {
          padding-top: 0;
        }
      }
    }
  }

  &__lines {
    width: 100%;
  }

  &__line {
    padding: 24px 0;
    border-bottom: solid 2px $neutral-100;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include sm() {
      padding: 0 0;
      display: flex;
      flex-direction: column;

      &:first-child {
        flex-direction: column-reverse;
      }
    }

    &:last-child {
      border-bottom: 0;

      @include md() {
        display: flex;
        flex-direction: column;
      }

      @include sm() {
        padding-top: 32px;
      }
    }
  }

  &__link-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &_politics {
      a {
        color: #262626;
      }

      @include sm() {
        flex-direction: column;
        text-align: center;

        .footer__link-item {
          margin-left: 0;
        }
      }
    }

    @include md() {
      padding-bottom: 32px;
    }
  }

  &__link-item {
    @include font(14, 24px, 400);
    margin-left: 24px;

    @include sm() {
      margin-top: 24px;
    }

    &:hover {
      a {
        text-decoration: underline;

        @include sm() {
          text-decoration: none;
        }
      }
    }

    a {
      @include sm() {
        text-decoration: underline;
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;

    img,
    svg {
      @include sm() {
        @include size(32px, 32px);
      }
    }

    @include sm() {
      padding-bottom: 32px;
      padding-top: 16px;
    }

    a {
      margin-right: 32px;

      @include sm() {
        margin-right: 40px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__rights {
    @include font(14, 24px, 400);
    color: #262626;

    @include md() {
      display: block;
      margin-bottom: 24px;
    }

    @include sm() {
      margin-bottom: 0;
    }
  }
}