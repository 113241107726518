.citation-item {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    @include font(32, 36px, 900);
    @include line-clamp(10);
    font-family: 'Montserrat';

    @include md() {
      @include line-clamp(8);
      line-height: 35.2px;
    }
    @include sm() {
      @include line-clamp(25);
    }
  }

  &__bottom-block {
    display: block;
    margin-top: 16px;
    border-left: solid 1px $neutral-100;
    padding-top: 8px;
    padding-left: 24px;

    &-title {
      @include font(14, 24px, 400);
      color: $neutral-70;
    }

    &-description {
      padding-top: 8px;
      @include line-clamp(3);
      max-width: 370px;
      text-transform: uppercase;
      @include font(18, 21.6px, 400);
      color: $neutral-100;
      @include sm() {
        @include line-clamp(2);
      }
    }
  }
}