.cookies-dialog {
  width: 414px;
  padding: 16px;
  position: fixed;
  z-index: 1000;
  bottom: 24px;
  right: 24px;
  background-color: $white;
  border: 1px solid #333333;
  box-shadow: 0px 12px 32px 8px rgba(0, 0, 0, 0.2);

  @include sm() {
    right: 0;
    bottom: 0;
  }

  @include xs() {
    padding: 8px;
    width: 335px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &__buttons {
    margin-top: 16px;
    display: flex;

    @include xs() {
      margin-top: 8.5px;
    }

    a,
    button {
      @include font(14, 24px, 400);
      padding: 11.5px 24px 10.5px 24px;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      border-radius: 100px;

      @include xs() {
        @include font(12, 24px);
      }

      @include xs() {
        padding: 7px 0;
      }
    }

    button {
      color: #E3E3E3;
      margin-right: 8px;
      cursor: pointer;
      background-color: $neutral-100;
    }

    a {
      display: block;
      color: $neutral-100;
      text-decoration: none;
      border: solid 1px $neutral-100;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;

    @include xs() {
      height: 20px;
    }

    svg {
      cursor: pointer;

      @include xs() {
        @include size(13.5px, 13.5px);
      }
    }

  }

  &__title {
    @include font(14, 22.5px, 700);

    @include xs() {
      @include font(12, 19.5px, 700);
    }
  }
}